@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Poppins, "Open Sans", sans-serif; }
.content-wrap{
    padding: 15px;
}
.v-align{ vertical-align: middle;}
.text-grey{
    color: #999;
}
.doctor-dashboard{
    padding: 8px;
    height: calc(100vh - 56px - 16px);
    overflow-y: auto;
  }
  .mb-0{
      margin-bottom: 0px!important;
  }
  .mt-10{
    margin-top: 10px!important;
}
.custom-card{
    border-radius: 6px;
    background-color: #fff;
    padding: 15px;
}
.mat-slider-thumb-label {
    transform: rotate(45deg) !important;
    border-radius: 50% 50% 0 !important;
  }

.mat-slider-thumb {
    transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
    opacity: 1 !important;
}

.cdk-overlay-pane{
    overflow: auto !important;
   }