@import "vita_typography";
@import "colors";

// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat-core($vita-typography);

// $vita-palette: (
//   50: #e3e6e6,
//   100: #b9c0c0,
//   200: #8a9796,
//   300: #5b6d6b,
//   400: #374d4c,
//   500: #142e2c,
//   600: #122927,
//   700: #0e2321,
//   800: #0b1d1b,
//   900: #061210,
//   A100: #54ffe6,
//   A200: #21ffdf,
//   A400: #00edca,
//   A700: #00d4b5,
//   contrast: (
//     50: rgba(black, 0.87),
//     100: rgba(black, 0.87),
//     200: rgba(black, 0.87),
//     300: white,
//     400: white,
//     500: white,
//     600: white,
//     700: white,
//     800: white,
//     900: white,
//     A100: rgba(black, 0.87),
//     A200: rgba(black, 0.87),
//     A400: rgba(black, 0.87),
//     A700: rgba(black, 0.87),
//   )
// );


/* For use in src/lib/core/theming/_palette.scss */
$vita-palette: (
    50 : #e3efff,
    100 : #b9d7ff,
    200 : #8abcff,
    300 : #5ba1ff,
    400 : #378dff,
    500 : #1479ff,
    600 : #1271ff,
    700 : #0e66ff,
    800 : #0b5cff,
    900 : #0649ff,
    A100 : #ffffff,
    A200 : #f3f6ff,
    A400 : #c0ceff,
    A700 : #a7baff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$vita-primary: mat-palette($vita-palette);
$vita-accent:  mat-palette($vita-palette);
$vita-warn:    mat-palette($mat-red);


// Create the theme object (a Sass map containing all of the palettes).
$vita-care-theme: mat-light-theme($vita-primary, $vita-accent, $vita-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($vita-care-theme);
