
// --------------------------- Material Customization ----------------------------------------

.vita-no-padding-dialog .mat-dialog-container {
  padding: 0;
}




// --------------------------- Global Styles ---------------------------------------------------
.padded-container{
  padding: 16px;
}

.toolbar-icon {
  padding: 0 14px;
  vertical-align: middle;
}
.toolbar-title {
  padding: 0 14px;
  vertical-align: middle;
}
.toolbar-spacer {
  flex: 1 1 auto;
}


::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0.5); 
  background-color:#d3d3d3d3;
}

::-webkit-scrollbar-thumb:window-inactive {
	overflow-y: hidden;
}

.mat-flat-button, .mat-fab, .mat-mini-fab{
  color: #fff!important;
}
.patient-avatar{
  border-radius: 50%;
}
.providerAssigned-snackbar{
  background-color: rgb(85, 192, 85);
  color: white;
  margin: 0 !important;
  font-size: 30px !important;
  font-weight:900 !important;
  position: absolute;
  right: 25px;
  top: 60px;


}
.providerRemoved-snackbar{
  background-color: rgb(243, 152, 66);
  color: white;
  margin: 0 !important;
  font-size: 30px !important;
  font-weight:900 !important;
  position: absolute;
  right: 25px;
  top: 60px;


}
.new-metrics-snackbar{
  background-color: black;
  color: white;
  margin: 0 !important;
  font-size: 34px !important;
  font-weight:900 !important;
  position: absolute;
  right: 25px;
  top: 60px;


}